import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div>
       <h1 className="head"><span>404</span></h1>
        <p>Oops! The Page you requested was not found!</p>
        <Link to="/">Back to Home</Link>
    </div>
    
  </Layout>
)

export default NotFoundPage
